<template>
<div class="row px-0">
    <div class="col-12 pt-2 px-0">
        <table id="basic-datatable" class="table table-striped  dt-responsive nowrap db"></table>
    </div>
</div>
</template>
<script>
/* eslint-disable */
import firebase from "firebase/app"
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import $ from 'jquery'

import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
const functions = firebase.app().functions('asia-east2')
import 'busy-load';

// import "datatables.net"
require('datatables.net-buttons-bs4')
require('datatables.net-responsive-bs4')
require('datatables.net-select-bs4')
require('datatables.net-bs4')
require('lightbox2/dist/js/lightbox.js')
require('lightbox2/dist/css/lightbox.css')
import Swal from 'sweetalert2'
export default {
    mounted() {
        let vue = this
        let columns = [
            {
                data: 'ownerId',
                title: "Owner Id",
            },
            {
                data: 'ppkey',
                title: "PromptPay Key",
            },
            {
                data: 'credit',
                title: "Credit",
                render: function (data, type, row) {
                    return Number(data) || 0
                }
            },
        ]
        let table = $('#basic-datatable').DataTable({
            order: [[2, "desc"]],
            pageLength: 50,
            lengthChange: !1,
            responsive: true,
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>"
                }
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
            columns: columns,
            select: 'single',
            columnDefs: [{
                "defaultContent": "",
                "targets": "_all",
                "className": "align-middle",
            }],
        })
        table.buttons().container().appendTo("#basic-datatable_wrapper .col-md-6:eq(0)")
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                let insert = []
                firebase.database().ref('client').once('value').then(function (data) {
                    data.forEach((result) => {
                        let uid = result.key
                        let l = result.val()
                        l.ownerId = uid
                        console.log(l)
                        insert.push(l)
                    })
                    table.rows.add(insert).draw(true)
                })
            }
            else
                window.location.href = "/login"
        })
    },
}
</script>

<style>
.dataTables_wrapper .dataTables_filter {
    display: none;
}

.dataTables_paginate {
    float: right;
    text-align: right;
}

table.dataTable tbody>tr.selected {
    color: white
}
table.dataTable tbody>tr.selected a {
    color: white
}

.dt-buttons {
    margin-bottom: 10px !important;
}
</style>
